import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button as MatButton } from 'react-materialize';
import { COLOR } from '../../utils/constants.js';
import SVG from 'react-inlinesvg';
import { getExtension } from '../../utils/utils.js'


const ButtonStyled = styled(MatButton)`
    display: flex;
    margin: ${props => props.margin ? props.margin : '0 0'};
    float: ${props => props.float ? props.float : ''};
    width: ${props => props.width ? props.width : ''};
    height: ${props => props.height ? props.height : '40px'};
    background: ${props => props.bg_color ? props.bg_color : 'none'};
    color: ${props => props.text_color ? props.text_color : COLOR.black};
    border: ${props => props.border_color ? '1px solid ' + props.border_color : 'none'};
    border-width: ${props => props.border_width ? props.border_width : 'none'};
    &:hover {
        background: ${props => props.bg_color 
            ? '#' + lightenColor(props.bg_color.replace('#', ''), 15) 
            : 'none' 
        };
        border: ${props => props.border_color 
            ? '1px solid #' + lightenColor(props.border_color.replace('#', ''), 15) 
            : 'none' 
        };
        border-width: ${props => props.border_width ? props.border_width : 'none'};
        color: ${props => props.text_color 
            ? '#' + lightenColor(props.text_color.replace('#', ''), 15) 
            : 'none' 
        };
    }
    &:focus {
        background: ${props => props.bg_color ? props.bg_color : 'none'};
    }
`;

const IconContent = styled.div`
    width: ${props => props.ic_size ? props.ic_size : '25px'};
    height: ${props => props.ic_size ? props.ic_size : '25px'};
    margin-right: ${props => props.ic_separation ? props.ic_separation : '15px'};
`;

const SVGStyled = styled(SVG)`
    display: block;
    width: ${props => props.ic_size ? props.ic_size : '25px'};
    height: ${props => props.ic_size ? props.ic_size : '25px'};
    ${props => props.ic_color ? 'fill: ' + props.ic_color : 'none'};
`;

const Icon = styled.img`
    display: block;
    height: 100%;
    width: 100%;
    margin-right: ${props => props.ic_separation ? props.ic_separation : '15px'};
`;

function lightenColor(color, percent) {
    var num = parseInt(color,16),
    amt = Math.round(1.70 * percent),
    R = (num >> 16) + amt,
    B = (num >> 8 & 0x00FF) + amt,
    G = (num & 0x0000FF) + amt;
    return (0x1000000 + (R<255?R<1?0:R:255)*0x10000 + (B<255?B<1?0:B:255)*0x100 + (G<255?G<1?0:G:255)).toString(16).slice(1);
};


const Button = (props) => {
    var icContent
    if (props.ic_path) {
        const icExtension = getExtension(props.ic_path)
        icContent = (icExtension === 'svg' && props.ic_color) ? 
        <IconContent
            ic_size={props.ic_size}
            ic_separation={props.separation}>
            <SVGStyled 
                ic_size={props.ic_size}
                src={props.ic_path}
                ic_color={props.ic_color}
                />
        </IconContent>
        :
        <IconContent
            ic_size={props.ic_size}
            ic_separation={props.separation} >
            <Icon 
                ic_size={props.ic_size}
                src={props.ic_path}/>
        </IconContent>;
    } else {
        icContent = null
    }

    return (
        <ButtonStyled
            id={ props.id }
            className='valign-wrapper'
            disabled={ props.disabled }
            onClick={ props.onClick }
            margin={ props.margin }
            width={ props.width }
            height={ props.height }
            bg_color={ props.bg_color }
            border_color={ props.border_color }
            border_width={ props.border_width }
            text_color={ props.text_color }
            float={ props.float }>
            { icContent }
            { props.children }
        </ButtonStyled>
    )
}

Button.defaultProps = {
    disabled: false
}

Button.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string, //Must have className to apply styled component css properties.
    width: PropTypes.string,
    height: PropTypes.string,
    disabled: PropTypes.bool, 
    onClick: PropTypes.func,
    ic_path: PropTypes.string,
    ic_size: PropTypes.string,
    ic_separation: PropTypes.string,
    ic_color: PropTypes.string,
    bg_color: PropTypes.string, 
    border_color: PropTypes.string, 
    border_width: PropTypes.string, 
    text_color: PropTypes.string, 
    margin: PropTypes.string,
    float: PropTypes.string
}

export default Button;